<!-- 返水详情页 -->
<template>
    <div class="viewsPages">
        <div class="page-rebate-info">
            <!-- <div class="line"></div> -->
            <div class="remark">{{$t("userPage.text241")}}</div>
            <!-- 搜索表单 -->
            <div class="input-box">
                <div class="text">{{$t("userPage.text242")+":"}}</div>
                <div class="input">
                    <Input v-model="form.min" placeholder="0" />
                    <span class="split-line">—</span>
                    <Input v-model="form.max" placeholder="0" />
                </div>
            </div>
            <!-- 时间筛选 -->
            <div class="input-box">
                <div class="text">{{$t("userPage.text243")+":"}}</div>
                <div class="date-box">
                    <DatePicker v-model="form.start" :placeholder='$t("userPage.text5")' />
                    <span class="split-line" >—</span>
                    <DatePicker v-model="form.end" :placeholder='$t("userPage.text5")' />
                </div>
                <div class="buttom-box">
                    <div class="query-buttom"  @click="query">{{$t("userPage.text6")}}</div>
                    <div class="reset-buttom" @click="reset">{{$t("userPage.text7")}}</div>
                </div>
            </div>
            <div class="content-box" v-if="list.length > 0">
                <!-- 表格 -->
                <Table :data="list" min-height="450" max-height="450">
                    <TableColumn align="center" prop="_createdAt" :label='$t("userPage.text244")' />
                    <TableColumn align="center" prop="vendorName" :label='$t("userPage.text245")' />
                    <TableColumn align="center" prop="gameName" :label='$t("userPage.text246")'/>
                    <TableColumn align="center" prop="_effectiveBet" :label='$t("userPage.text247")' />
                    <TableColumn align="center" prop="_rebateAmount" :label='$t("userPage.text248")' />
                </Table>
            </div>
            <div v-else class="null-box">
                <img loading="lazy" v-lazy="require('../../../assets/images/my/null.png')">
                <div>{{$t("userPage.text12")}}</div>
            </div>
        </div>
    </div>
</template>

<script>
import { Input, DatePicker, Table, TableColumn,Message} from 'element-ui'
import { _getTime,_getDifferDate } from '../../../core/utils/utils'
import { _getDetailByBetNo,_getRebateAmountDetail } from '../../../core/api/modules/records'
import { _jump } from '@/core/utils/utils'
import { mapGetters } from 'vuex'

export default {
    components: {
        Input, DatePicker, Table, TableColumn
    },
    data () {
        return {
            betNo: '', // 订单号
            form: {
                min: '',
                max: '',
                start: '',
                end: ''
            },
            list: [], // 数据们
        }
    },
    created () {
        this.query()
    },
    computed:{
        ...mapGetters([
            'userData'
        ])
    },
    methods: {
        _jump,
        // 重置
        reset () {
            this.form = {
                min: '',
                max: '',
                start: '',
                end: ''
            }
        },
        query() {
            this.betNo = this.$route.query.betNo
            if(this.form.start && this.form.end){
                let day = _getDifferDate(this.form.start,this.form.end);
                if(day > 30) return Message.warning(this.$t("userPage.text249")) 
            }
            if(this.betNo) {
                this.getDetailByBetNo()
            } else {
                this.getRebateAmountDetail()
            }   
        },
        // 返水详情实时返水
        getRebateAmountDetail() {
            let params = {
                memberId: this.userData.user_id,
                status:0,
                delFlag:0,
                moneyMax: isNaN(this.form.max) ? '' : this.form.max,
                moneyMin: isNaN(this.form.min) ? '' : this.form.min,
                startTime: this.form.start ? _getTime(this.form.start) + ' 00:00:00' : '',
                endTime: this.form.end ? _getTime(this.form.end) + ' 23:59:59' : '',
            }
            setTimeout(() => {
                _getRebateAmountDetail(params).then(res => {
                    if (res) {
                        this.list = res
                    }
                })
            })
        },
        // 返水详情批次返水
        getDetailByBetNo () {
            let params = {
                betNo: this.betNo,
                moneyMax: isNaN(this.form.max) ? '' : this.form.max,
                moneyMin: isNaN(this.form.min) ? '' : this.form.min,
                startTime: this.form.start ? _getTime(this.form.start) + ' 00:00:00' : '',
                endTime: this.form.end ? _getTime(this.form.end) + ' 23:59:59' : '',
            }
            setTimeout(() => {
                _getDetailByBetNo(params).then(res => {
                    if (res) {
                        this.list = res
                    }
                })
            })
        }
    }
}
</script>

<style scoped lang="scss">
@import "~@/assets/css/page-rebate-info.scss";
</style>